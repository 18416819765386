import React from "react"
import { graphql } from "gatsby"

import Container from "../components/Container"
import PageHeader from "../components/PageHeader"
import Box from "../components/Box"
import Grid from "../components/Grid"
import Heading from "../components/Heading"
import Wave from "../components/Wave"
import P from "../components/Paragraph"
import SeoHelmet from "../components/Seo"

const ProjectLayout = ({ data }) => {
  const project = data.datoCmsProject
  return (
    <>
      <SeoHelmet
        title={project.title}
        description={project.intro}
        url={`http://lapidus.se/case-studies/${project.slug}`}
        cover={project.mainImage && project.mainImage.url}
      />
      <PageHeader title={project.title} subtitle={project.intro} />
      <Box position="relative">
        <Box position="relative" zIndex={2}>
          <Container size="sm" mt="5rem">
            <img
              src={project.mainImage && `${project.mainImage.url}`}
              alt={project.title}
              width="100%"
            />
          </Container>
        </Box>
        <Box position="absolute" top="50%" bottom="0" left="0" right="0">
          <Wave color="lightGrey" variant="top2" height="100%" />
        </Box>
      </Box>
      <Box>
        <Box bg="lightGrey">
          <Container py="5rem">
            <Grid gridColumnGap={["0rem","2.5rem"]}>
              <Box gridColumn={["span 12", "span 6", "span 3"]}>
                <Heading as="h2" fontSize={["xs", "sm"]} my="0.75rem">
                  {"Client"}
                </Heading>
                <Heading
                  as="h2"
                  fontSize={["xs", "sm"]}
                  my="0.75rem"
                  color="darkGrey"
                  fontWeight="500"
                >
                  {project.client}
                </Heading>
                {project.partner && (
                  <P size="lg" my="0.75rem" color="darkGrey">
                    {"Developed as consultants to " + project.partner}
                  </P>
                )}
              </Box>
              <Box gridColumn={["span 12", "span 6", "span 3"]}>
                <Heading as="h2" fontSize={["xs", "sm"]} my="0.75rem">
                  {"Services"}
                </Heading>
                <P size="lg" my="0.75rem" color="darkGrey">
                  {project.services.slice(0, 3).map(({ id, title }) => (
                    <P key={id} size="lg" my="0.25rem" color="darkGrey">
                      {title}
                    </P>
                  ))}
                </P>
              </Box>
              <Box gridColumn={["span 12", "span 6", "span 3"]}>
                <Heading as="h2" fontSize={["xs", "sm"]} my="0.75rem">
                  {"Technologies"}
                </Heading>
                {project.technologies.map(({ id, title }) => (
                  <P key={id} size="lg" my="0.25rem" color="darkGrey">
                    {title}
                  </P>
                ))}
              </Box>
              <Box gridColumn={["span 12", "span 6", "span 3"]}>
                <Heading as="h2" fontSize={["xs", "sm"]} my="0.75rem">
                  {"Project links"}
                </Heading>
                <Heading
                  as="h2"
                  fontSize={["xs", "sm"]}
                  my="0.75rem"
                  color="darkGrey"
                  fontWeight="500"
                >
                  {project.link ? (
                    <a target="_blank" href={project.link}>
                      {project.link
                        .split("/")
                        .filter(s => s !== "https:" && s !== "http:")
                        .slice(1)
                        .join("/")}
                    </a>
                  ) : (
                    "Not available at this time"
                  )}
                </Heading>
              </Box>
            </Grid>
          </Container>
        </Box>

        <Container size="sm" mt="5rem">
          <Heading as="h2" fontSize={["lg", "xl"]}>
            {"Would you like more information about this project?"}
          </Heading>
          <P fontSize={["sm", "lg"]}>
            {
              "Schedule a call with us below and we're happy to talk you through our design and development process in detail. Our experts also arrange workshops and speak on topics such as Open data, Data visualization and Data journalism."
            }
          </P>
        </Container>
        <Wave color="lightGrey" variant="bottom" />
      </Box>
      <Wave color="white" variant="bottom3" bg="navy" />
    </>
  )
}

export const query = graphql`
  query ProjectQuery($slug: String!) {
    datoCmsProject(slug: { eq: $slug }) {
      id
      title
      client
      partner
      slug
      intro
      mainImage {
        alt
        url
      }
      cover {
        alt
        url
      }
      services {
        title
      }
      technologies {
        title
      }
      link
      location {
        latitude
        longitude
      }
    }
  }
`

export default ProjectLayout
